class CantonService {
  getCantones() {
    return [
      { id: 0, nombre: "TODAS DE ECUADOR" },
      { id: 150, nombre: "24 DE MAYO" },
      { id: 215, nombre: "AGUARICO" },
      { id: 44, nombre: "ALAUSI" },
      { id: 76, nombre: "ALFREDO BAQUERIZO MORENO (JUJÁN)" },
      { id: 186, nombre: "AMBATO" },
      { id: 101, nombre: "ANTONIO ANTE" },
      { id: 177, nombre: "ARAJUNO" },
      { id: 170, nombre: "ARCHIDONA" },
      { id: 54, nombre: "ARENILLAS" },
      { id: 72, nombre: "ATACAMES" },
      { id: 55, nombre: "ATAHUALPA" },
      { id: 23, nombre: "AZOGUES" },
      { id: 123, nombre: "BABA" },
      { id: 122, nombre: "BABAHOYO" },
      { id: 77, nombre: "BALAO" },
      { id: 56, nombre: "BALSAS" },
      { id: 78, nombre: "BALZAR" },
      { id: 187, nombre: "BAÑOS DE AGUA SANTA" },
      { id: 24, nombre: "BIBLIÁN" },
      { id: 31, nombre: "BOLÍVAR" },
      { id: 136, nombre: "BOLÍVAR" },
      { id: 131, nombre: "BUENA FÉ" },
      { id: 21, nombre: "CALUMA" },
      { id: 107, nombre: "CALVAS" },
      { id: 15, nombre: "CAMILO PONCE ENRÍQUEZ" },
      { id: 173, nombre: "CARLOS JULIO AROSEMENA TOLA" },
      { id: 212, nombre: "CASCALES" },
      { id: 108, nombre: "CATAMAYO" },
      { id: 179, nombre: "CAYAMBE" },
      { id: 25, nombre: "CAÑAR" },
      { id: 109, nombre: "CELICA" },
      { id: 201, nombre: "CENTINELA DEL CÓNDOR" },
      { id: 188, nombre: "CEVALLOS" },
      { id: 110, nombre: "CHAGUARPAMBA" },
      { id: 46, nombre: "CHAMBO" },
      { id: 57, nombre: "CHILLA" },
      { id: 17, nombre: "CHILLANES" },
      { id: 18, nombre: "CHIMBO" },
      { id: 196, nombre: "CHINCHIPE" },
      { id: 137, nombre: "CHONE" },
      { id: 11, nombre: "CHORDELEG" },
      { id: 47, nombre: "CHUNCHI" },
      { id: 79, nombre: "COLIMES" },
      { id: 45, nombre: "COLTA" },
      { id: 95, nombre: "CORONEL MARCELINO MARIDUEÑA" },
      { id: 102, nombre: "COTACACHI" },
      { id: 1, nombre: "CUENCA" },
      { id: 52, nombre: "CUMANDÁ" },
      { id: 213, nombre: "CUYABENO" },
      { id: 80, nombre: "DAULE" },
      { id: 81, nombre: "DURÁN" },
      { id: 28, nombre: "DÉLEG" },
      { id: 19, nombre: "ECHEANDÍA" },
      { id: 138, nombre: "EL CARMEN" },
      { id: 171, nombre: "EL CHACO" },
      { id: 82, nombre: "EL EMPALME" },
      { id: 58, nombre: "EL GUABO" },
      { id: 12, nombre: "EL PAN" },
      { id: 200, nombre: "EL PANGUI" },
      { id: 27, nombre: "EL TAMBO" },
      { id: 83, nombre: "EL TRIUNFO" },
      { id: 68, nombre: "ELOY ALFARO" },
      { id: 67, nombre: "ESMERALDAS" },
      { id: 32, nombre: "ESPEJO" },
      { id: 111, nombre: "ESPÍNDOLA" },
      { id: 139, nombre: "FLAVIO ALFARO" },
      { id: 98, nombre: "GENERAL ANTONIO ELIZALDE" },
      { id: 2, nombre: "GIRÓN" },
      { id: 208, nombre: "GONZALO PIZARRO" },
      { id: 112, nombre: "GONZANAMÁ" },
      { id: 14, nombre: "GUACHAPALA" },
      { id: 3, nombre: "GUALACEO" },
      { id: 158, nombre: "GUALAQUIZA" },
      { id: 48, nombre: "GUAMOTE" },
      { id: 49, nombre: "GUANO" },
      { id: 16, nombre: "GUARANDA" },
      { id: 75, nombre: "GUAYAQUIL" },
      { id: 163, nombre: "HUAMBOYA" },
      { id: 59, nombre: "HUAQUILLAS" },
      { id: 100, nombre: "IBARRA" },
      { id: 205, nombre: "ISABELA" },
      { id: 99, nombre: "ISIDRO AYORA" },
      { id: 154, nombre: "JAMA" },
      { id: 155, nombre: "JARAMIJÓ" },
      { id: 140, nombre: "JIPIJAPA" },
      { id: 141, nombre: "JUNÍN" },
      { id: 74, nombre: "LA CONCORDIA" },
      { id: 216, nombre: "LA JOYA DE LOS SACHAS" },
      { id: 220, nombre: "LA LIBERTAD" },
      { id: 37, nombre: "LA MANÁ" },
      { id: 26, nombre: "LA TRONCAL" },
      { id: 207, nombre: "LAGO AGRIO" },
      { id: 66, nombre: "LAS LAJAS" },
      { id: 22, nombre: "LAS NAVES" },
      { id: 36, nombre: "LATACUNGA" },
      { id: 159, nombre: "LIMÓN INDANZA" },
      { id: 166, nombre: "LOGROÑO" },
      { id: 106, nombre: "LOJA" },
      { id: 96, nombre: "LOMAS DE SARGENTILLO" },
      { id: 217, nombre: "LORETO" },
      { id: 113, nombre: "MACARÁ" },
      { id: 53, nombre: "MACHALA" },
      { id: 142, nombre: "MANTA" },
      { id: 60, nombre: "MARCABELÍ" },
      { id: 180, nombre: "MEJIA" },
      { id: 175, nombre: "MERA" },
      { id: 84, nombre: "MILAGRO" },
      { id: 33, nombre: "MIRA" },
      { id: 133, nombre: "MOCACHE" },
      { id: 189, nombre: "MOCHA" },
      { id: 124, nombre: "MONTALVO" },
      { id: 143, nombre: "MONTECRISTI" },
      { id: 34, nombre: "MONTÚFAR" },
      { id: 157, nombre: "MORONA" },
      { id: 69, nombre: "MUISNE" },
      { id: 4, nombre: "NABÓN" },
      { id: 197, nombre: "NANGARITZA" },
      { id: 85, nombre: "NARANJAL" },
      { id: 86, nombre: "NARANJITO" },
      { id: 97, nombre: "NOBOL" },
      { id: 121, nombre: "OLMEDO" },
      { id: 152, nombre: "OLMEDO" },
      { id: 214, nombre: "ORELLANA" },
      { id: 103, nombre: "OTAVALO" },
      { id: 10, nombre: "OÑA" },
      { id: 167, nombre: "PABLO SEXTO" },
      { id: 144, nombre: "PAJÁN" },
      { id: 202, nombre: "PALANDA" },
      { id: 130, nombre: "PALENQUE" },
      { id: 87, nombre: "PALESTINA" },
      { id: 50, nombre: "PALLATANGA" },
      { id: 160, nombre: "PALORA" },
      { id: 114, nombre: "PALTAS" },
      { id: 38, nombre: "PANGUA" },
      { id: 203, nombre: "PAQUISHA" },
      { id: 61, nombre: "PASAJE" },
      { id: 174, nombre: "PASTAZA" },
      { id: 190, nombre: "PATATE" },
      { id: 5, nombre: "PAUTE" },
      { id: 151, nombre: "PEDERNALES" },
      { id: 88, nombre: "PEDRO CARBO" },
      { id: 181, nombre: "PEDRO MONCAYO" },
      { id: 184, nombre: "PEDRO VICENTE MALDONADO" },
      { id: 51, nombre: "PENIPE" },
      { id: 145, nombre: "PICHINCHA" },
      { id: 104, nombre: "PIMAMPIRO" },
      { id: 119, nombre: "PINDAL" },
      { id: 62, nombre: "PIÑAS" },
      { id: 93, nombre: "PLAYAS" },
      { id: 63, nombre: "PORTOVELO" },
      { id: 135, nombre: "PORTOVIEJO" },
      { id: 6, nombre: "PUCARA" },
      { id: 125, nombre: "PUEBLOVIEJO" },
      { id: 153, nombre: "PUERTO LÓPEZ" },
      { id: 185, nombre: "PUERTO QUITO" },
      { id: 39, nombre: "PUJILI" },
      { id: 209, nombre: "PUTUMAYO" },
      { id: 115, nombre: "PUYANGO" },
      { id: 191, nombre: "QUERO" },
      { id: 126, nombre: "QUEVEDO" },
      { id: 172, nombre: "QUIJOS" },
      { id: 120, nombre: "QUILANGA" },
      { id: 70, nombre: "QUININDÉ" },
      { id: 134, nombre: "QUINSALOMA" },
      { id: 178, nombre: "QUITO" },
      { id: 43, nombre: "RIOBAMBA" },
      { id: 73, nombre: "RIOVERDE" },
      { id: 146, nombre: "ROCAFUERTE" },
      { id: 182, nombre: "RUMIÑAHUI" },
      { id: 40, nombre: "SALCEDO" },
      { id: 221, nombre: "SALINAS" },
      { id: 91, nombre: "SALITRE (URBINA JADO)" },
      { id: 89, nombre: "SAMBORONDÓN" },
      { id: 204, nombre: "SAN CRISTÓBAL" },
      { id: 7, nombre: "SAN FERNANDO" },
      { id: 92, nombre: "SAN JACINTO DE YAGUACHI" },
      { id: 164, nombre: "SAN JUAN BOSCO" },
      { id: 71, nombre: "SAN LORENZO" },
      { id: 20, nombre: "SAN MIGUEL" },
      { id: 183, nombre: "SAN MIGUEL DE LOS BANCOS" },
      { id: 105, nombre: "SAN MIGUEL DE URCUQUÍ" },
      { id: 35, nombre: "SAN PEDRO DE HUACA" },
      { id: 192, nombre: "SAN PEDRO DE PELILEO" },
      { id: 156, nombre: "SAN VICENTE" },
      { id: 147, nombre: "SANTA ANA" },
      { id: 176, nombre: "SANTA CLARA" },
      { id: 206, nombre: "SANTA CRUZ" },
      { id: 219, nombre: "SANTA ELENA" },
      { id: 8, nombre: "SANTA ISABEL" },
      { id: 90, nombre: "SANTA LUCÍA" },
      { id: 64, nombre: "SANTA ROSA" },
      { id: 161, nombre: "SANTIAGO" },
      { id: 193, nombre: "SANTIAGO DE PÍLLARO" },
      { id: 218, nombre: "SANTO DOMINGO" },
      { id: 41, nombre: "SAQUISILÍ" },
      { id: 116, nombre: "SARAGURO" },
      { id: 13, nombre: "SEVILLA DE ORO" },
      { id: 210, nombre: "SHUSHUFINDI" },
      { id: 42, nombre: "SIGCHOS" },
      { id: 9, nombre: "SIGSIG" },
      { id: 94, nombre: "SIMÓN BOLÍVAR" },
      { id: 117, nombre: "SOZORANGA" },
      { id: 148, nombre: "SUCRE" },
      { id: 211, nombre: "SUCUMBÍOS" },
      { id: 162, nombre: "SUCÚA" },
      { id: 29, nombre: "SUSCAL" },
      { id: 165, nombre: "TAISHA" },
      { id: 169, nombre: "TENA" },
      { id: 194, nombre: "TISALEO" },
      { id: 168, nombre: "TIWINTZA" },
      { id: 149, nombre: "TOSAGUA" },
      { id: 30, nombre: "TULCÁN" },
      { id: 127, nombre: "URDANETA" },
      { id: 132, nombre: "VALENCIA" },
      { id: 128, nombre: "VENTANAS" },
      { id: 129, nombre: "VÍNCES" },
      { id: 198, nombre: "YACUAMBI" },
      { id: 199, nombre: "YANTZAZA (YANZATZA)" },
      { id: 195, nombre: "ZAMORA" },
      { id: 118, nombre: "ZAPOTILLO" },
      { id: 65, nombre: "ZARUMA" }
    ];
  }
}

export default new CantonService();
